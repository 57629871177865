import { useEffect, useState } from 'react';
import { Alert, Snackbar } from '@material-ui/core';

const url = `${import.meta.env.VITE_API_URL}/health`;

async function isReachable() {
  try {
    const response = await fetch(url);
    return response.ok;
  } catch {
    return false;
  }
}

export default function OfflinePrompt() {
  const [showOfflineAlert, setShowOfflineAlert] = useState(false);
  const [showOnlineAlert, setShowOnlineAlert] = useState(false);

  useEffect(() => {
    async function handleOffline() {
      if (navigator.onLine) {
        // confirm network connection using /health endpoint
        const isRespOk = await isReachable();
        if (isRespOk) {
          // handle back to online status
          if (showOfflineAlert) {
            setShowOfflineAlert(false);
            setShowOnlineAlert(true);
          }
        } else {
          // handle offline status
          setShowOfflineAlert(true);
        }
      } else {
        // handle offline snackbar
        setShowOfflineAlert(true);
      }
    }
    window.addEventListener('online', handleOffline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOffline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [showOfflineAlert]);

  return (
    <>
      <Snackbar
        open={showOfflineAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error">
          Seems like you have internet connectivity issue! Any changes will not
          be synced at the moment.
        </Alert>
      </Snackbar>

      <Snackbar
        open={showOnlineAlert}
        autoHideDuration={3000}
        onClose={() => setShowOnlineAlert(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="success">
          Connection Re-established! You can continue using the application now
        </Alert>
      </Snackbar>
    </>
  );
}
