import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { PostHogProvider } from 'posthog-js/react';
import { CircularProgress } from '@material-ui/core';
import { LocalizationProvider } from '@material-ui/lab';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ReactQueryDevtools } from 'react-query/devtools';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import AllRoutes from 'src/routes';
import Auth from 'src/components/auth';
import Posthog from 'src/components/posthog';
import Logrocket from 'src/components/logrocket';
import LaunchDarkly from 'src/components/launch-darkly';
import OfflinePrompt from 'src/components/offline-prompt';
import { ANONYMOUS_USER_KEY } from 'src/utils/feature-flags';
import { queryClient, QueryClientProvider } from 'src/utils/query-client';

// Update the Button's color prop options
declare module '@material-ui/core/Button' {
  interface ButtonPropsColorOverrides {
    error: true;
    info: true;
  }
}

declare global {
  interface Window {
    analytics: any;
  }
}

function App() {
  const fallback = (
    <div className="flex items-center justify-center w-screen h-screen">
      <CircularProgress />
    </div>
  );

  const POSTHOG_API_KEY = import.meta.env.VITE_PUBLIC_POSTHOG_KEY ?? '';
  const POSTHOG_HOST = import.meta.env.VITE_PUBLIC_POSTHOG_HOST;

  return (
    <QueryClientProvider client={queryClient}>
      <PostHogProvider
        apiKey={POSTHOG_API_KEY}
        options={{ api_host: POSTHOG_HOST, autocapture: false }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <GoogleOAuthProvider
            clientId={import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID as string}
          >
            <Auth>
              <Posthog />
              <Suspense fallback={fallback}>
                <BrowserRouter>
                  <AllRoutes />
                  <LaunchDarkly />
                </BrowserRouter>
              </Suspense>
              <OfflinePrompt />
              {import.meta.env.PROD && <Logrocket />}
            </Auth>
          </GoogleOAuthProvider>
          {import.meta.env.DEV && <ReactQueryDevtools />}
        </LocalizationProvider>
      </PostHogProvider>
    </QueryClientProvider>
  );
}

export default withLDProvider({
  clientSideID: import.meta.env.VITE_LAUNCHDARKLY_KEY,
  context: {
    kind: 'multi',
    user: { key: ANONYMOUS_USER_KEY },
    endorsement: { key: 'endorsement-123abc', programType: 'unknown' },
  },
})(App);
